<!--
 * @Description: 全部商品页面组件(包括全部商品,商品分类,商品搜索)
 * @Author: hai-27
 * @Date: 2020-02-07 16:23:00
 * @LastEditors: hai-27
 * @LastEditTime: 2020-03-08 12:11:13
 -->
<template>
  <div class="goods" id="goods" name="goods">
    <!-- 面包屑 -->
    <!-- <div class="breadcrumb">
      <el-breadcrumb separator-class="el-icon-arrow-right">
        <el-breadcrumb-item to="/">首页</el-breadcrumb-item>
        <el-breadcrumb-item>全部商品</el-breadcrumb-item>
        <el-breadcrumb-item v-if="search">搜索</el-breadcrumb-item>
        <el-breadcrumb-item v-else>分类</el-breadcrumb-item>
        <el-breadcrumb-item v-if="search">{{ search }}</el-breadcrumb-item>
      </el-breadcrumb>
    </div> -->
    <!-- 面包屑END -->

    <!-- 分类标签  v-infinite-scroll="getCategory" -->
    <div
      class="nav"
      v-if="categoryList.length > 0"
      v-loading="categoryList.length == 0"
    >
      <div class="product-nav" infinite-scroll-disabled="navDisabled">
        <!-- <div class="title">分类</div> -->
        <div
          v-for="item in categoryList"
          :key="item.Id"
          v-show="item.TwoTypes.length > 0"
        >
          <div class="title" :title="item.Name">
            <span>{{ item.Name }}</span>
          </div>
          <!-- <el-tabs v-model="activeName" type="card" class="eltabs">
            <el-tab-pane
              v-for="items in item.TwoTypes"
              :key="items.Id"
              :label="items.Name"
              :name="'' + items.Id"
            />
          </el-tabs> -->
          <div class="goodsTypeTag">
            <el-tag
              v-for="items in item.TwoTypes"
              :key="items.Id"
              :type="activeName == items.Id ? 'success' : 'info'"
              @click="clickTag(items.Id)"
              effect="dark"
              class="tagitem"
              ><div style="cursor: pointer">{{ items.Name }}</div></el-tag
            >
          </div>
        </div>
        <p v-if="false">加载中...</p>
        <p v-if="true">没有更多了</p>
      </div>
    </div>
    <!-- 分类标签END -->

    <!-- 主要内容区 -->
    <div class="main">
      <div class="list" v-loading="loading">
        <div class="desc" style="margin-left: 14.5px; margin-bottom: 10px">
          <el-button-group>
            <el-button
              type="mini"
              :icon="priceDesc ? 'el-icon-bottom' : 'el-icon-top'"
              @click="onDesc"
              >价格</el-button
            >
          </el-button-group>
        </div>
        <MyList :search="search" :list="product" v-if="product.length > 0"></MyList>
        <div v-else-if="!loading" class="none-product">
          抱歉没有找到相关的商品，请看看其他的商品
        </div>
      </div>
      <!-- 分页 -->
      <div class="pagination">
        <el-pagination
          background
          layout="prev, pager, next"
          :page-size="pageSize"
          :total="total"
          @current-change="currentChange"
        ></el-pagination>
      </div>
      <!-- 分页END -->
    </div>
    <!-- 主要内容区END -->
  </div>
</template>
<script>
export default {
  data() {
    return {
      categoryList: "", //分类列表
      categoryID: -1, // 分类id
      product: "", // 商品列表
      productList: "",
      total: 0, // 商品总量
      pageSize: 15, // 每页显示的商品数量
      currentPage: 1, //当前页码
      activeName: "-1", // 分类列表当前选中的id
      search: "", // 搜索条件
      loading: true,
      priceDesc: true,
      priceDescText: "",
    };
  },
  created() {
    // 获取分类列表
    // this.getCategory();
  },
  computed: {
    navDisabled() {
      return false;
    },
  },
  activated() {
    
    this.activeName = "-1"; // 初始化分类列表当前选中的id为-1
    this.search = "";
    // 如果路由没有传递参数，默认为显示全部商品
    if (Object.keys(this.$route.query).length == 0) {
      // this.categoryID = 0;
      this.activeName = "0";
      return;
    }
    // 如果路由传递了categoryID，则显示对应的分类商品
    if (this.$route.query.categoryID != undefined) {
      const categoryID = this.$route.query.categoryID;
      console.log(categoryID ,1)
      // this.categoryID = categoryID;
      this.activeName = "" + categoryID;
      return;
    }
    else{
      this.categoryID = -1;
    }
    // 如果路由传递了search，则为搜索，显示对应的分类商品
    if (this.$route.query.search != undefined) {
      this.search = this.$route.query.search;
    }
     
  },
  beforeRouteLeave(to, from, next) {
    next();
  },
  watch: {
    // 监听点击了哪个分类标签，通过修改分类id，响应相应的商品
    activeName: function (val) {
      if (val >= 0) {
        this.categoryID = val;
      }
      // 初始化商品总量和当前页码
      this.total = 0;
      this.currentPage = 1;
    },
    // 监听搜索条件，响应相应的商品
    search: function (val) {
      if (val != "") {
        this.currentPage = 1;
        this.total = 0;
        this.getProductBySearch(val);
      }
    },
    // 监听分类id，响应相应的商品
    categoryID: function (val) {
      console.log(val,123)
      if (val >= 0) {
        // 更新地址栏链接，方便刷新页面可以回到原来的页面
        this.$router.push({
          path: "/goods",
          query: { categoryID: this.categoryID },
        });
        this.priceDescText = "";
        this.getData();
        this.search = "";
      }
    },
    // 监听路由变化，更新路由传递了搜索条件
    $route: function (val) {
      if (val.path == "/goods") {
        if (val.query.search != undefined) {
           
          this.activeName = "-1";
          this.search = val.query.search;
          this.categoryID = -1;
          this.priceDescText = "";
          console.log( this.categoryID,4)
        } else if (val.query.categoryID) {
          this.categoryID = val.query.categoryID;
           console.log( this.categoryID,12)
        }
      }
    },
  },
  methods: {
    onDesc() {
      this.priceDesc = !this.priceDesc;
      this.priceDescText = "OrderBy=Price";
      if (this.priceDesc) {
        this.priceDescText += " desc";
      }
      this.currentChange(1);
    },
    clickTag(id) {
      this.activeName = id;
    },
    // 返回顶部
    backtop() {
      const timer = setInterval(function () {
        const top =
          document.documentElement.scrollTop || document.body.scrollTop;
        const speed = Math.floor(-top / 5);
        document.documentElement.scrollTop = document.body.scrollTop =
          top + speed;
        if (top === 0) {
          clearInterval(timer);
        }
      }, 20);
    },
    // 页码变化调用currentChange方法
    currentChange(currentPage) {
      this.currentPage = currentPage;
      if (this.search != "") {
        this.getProductBySearch();
      } else {
        this.getData();
      }
      this.backtop();
    },
    // 向后端请求分类列表数据
    getCategory() {
      if (this.categoryList.length > 0) {
        console.log("加载数据");
        return;
      }
      this.$axios
        .get("/api/GoodsType/GetAllType", {})
        .then((res) => {
          const val = {
            Id: 0,
            Name: "全部",
            TwoTypes: [
              {
                Id: 0,
                Name: "全部商品",
              },
            ],
          };
          const cate = res.data;
          cate.unshift(val);
          this.categoryList = cate;
        })
        .catch((err) => {
          return Promise.reject(err);
        });
    },
    // 向后端请求全部商品或分类商品数据
    getData() {
      this.loading = true;
      // 如果分类列表为空则请求全部商品数据，否则请求分类商品数据
      let api = "/api/VouchersGoods/GetGoodsByTypeId";
      if (this.priceDescText) {
        api += "?" + this.priceDescText;
      }
      this.$axios
        .get(api, {
          params: {
            TypeId: this.categoryID,
            PageIndex: this.currentPage,
            PageSize: this.pageSize,
          },
        })
        .then((res) => {
          this.product = res.data;
          this.total = res.itemCount;
          this.loading = false;
        });
    },
    // 通过搜索条件向后端请求商品数据
    getProductBySearch() {
      this.loading = true;
      let api = "/api/VouchersGoods/GetGoodsByName/GetGoodsByName";

      if (this.priceDescText) {
        api += "?" + this.priceDescText;
      }

      var userId=this.$store.getters.getUserId;   
   
      this.$axios
        .get(api, {
          params: {
            Name: this.search,
            PageSize: this.pageSize,
            PageIndex: this.currentPage,
            UserId:userId
          },
        })
        .then((res) => {
          this.product = res.data;
          this.total = res.itemCount;
          this.loading = false;
        })
        .catch((err) => {
          return Promise.reject(err);
        });
    },
  },
};
</script>

<style scoped>
.goods {
  /* background-color: #fafafa; */
}
/* 面包屑CSS */
.el-tabs--card .el-tabs__header {
  border-bottom: none;
}
.goods .breadcrumb {
  height: 50px;
  background-color: white;
}
.goods .breadcrumb .el-breadcrumb {
  width: 1225px;
  line-height: 30px;
  font-size: 16px;
  margin: 0 auto;
}
/* 面包屑CSS END */

/* 分类标签CSS */
.goods .nav {
  background-color: white;
}
.goods .nav .product-nav {
  width: 1225px;
  /* height: 40px; */
  line-height: 40px;
  margin: 0 auto;
  max-height: 200px;
  overflow: auto;
}
.nav .product-nav .title {
  width: 100px;
  font-size: 16px;
  font-weight: 700;
  float: left;
  line-height: inherit;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}
.nav .product-nav p {
  text-align: center;
}
.eltabs {
  margin: 0px 110px;
  padding: 0 10px;
  width: 89%;
}
.el-tabs--card .el-tabs__header {
  border-bottom: 1px solid #e4e7ed;
}
.goodsTypeTag {
  margin-left: 100px;
  margin-top: 10px;
  border-bottom: 1px solid #e4e7ed;
  padding-bottom: 10px;
}
.goodsTypeTag .tagitem {
  margin-right: 10px;
}
/* 分类标签CSS END */

/* 主要内容区CSS */
.goods .main {
  margin: 0 auto;
  max-width: 1225px;
}
.goods .main .list {
  min-height: 650px;
  padding-top: 14.5px;
  margin-left: -13.7px;
  overflow: auto;
}
.goods .main .pagination {
  height: 50px;
  text-align: center;
}
.goods .main .none-product {
  color: #333;
  /* margin-left: 13.7px; */
  margin-top: 25%;
  text-align: center;
}

.el-button--mini{
  border-color:   var(--ThemeColor,#ff6701);
  background-color:   var(--ThemeColor,#ff6701);
  color:white;
}


.el-button--mini:hover{
  opacity: 0.8;
}


/* 主要内容区CSS END */
</style>